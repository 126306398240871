import "./navBar.css";
import Logo from "../../assets/Logos/soundcheckpro.png"

const Navbar = () => {
  return (
    <nav className="Header">
      {/* <h1 className="Header">SoundcheckPro Web</h1> */}
      <img src={Logo}></img>
      {/* <a href="/html/">Home</a> |<a href="/css/">Help</a> |<a href="/js/">Contact</a> */}
    </nav>
  );
};

export default Navbar;
