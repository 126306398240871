import "./playerPopup.css";
import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from "react";
import DemoFile1 from "../../assets/audio/Session_1/Overheads.mp3";
import DemoFile2 from "../../assets/audio/Session_1/Bass.mp3";
import DemoFile3 from "../../assets/audio/Session_1/Gtr.mp3";
import DemoFile4 from "../../assets/audio/Session_1/LeadVox.mp3";
import PeakMeter2 from "../PeakMeter/PeakMeter2";
import { useAudio } from "../../lib/ContextProvider";
import { gEvent } from "../../lib/gTagAnalytics";
// import WebKnob from "../WebKnob/WebKnob";

const PlayerPopup = forwardRef((props, ref) => {
  const { audioContext, mixer } = useAudio();

  const mRef = useRef(); //Reference in order to call PopupPlayers Methods
  const isShowing = props.display;
  const [playerNodes, setNodes] = useState([]); //Actually gets played
  const [playerNodesCopy, setNodesCopy] = useState([]); //Holds a copy for "re-playing" / perhaps should be buffer
  const [isPlaying, setIsPlaying] = useState(false);
  const [mNode, setMNode] = useState(audioContext.createGain());

  const [fileNames, setFileNames] = useState([]); //Holds a copy for "re-playing" / perhaps should be buffer

  //Used to trigger method in the PopupPlayer child componenet
  function assignViz(node) {
    mRef.current.ConnectMeter(mNode, audioContext);
  }

  function escapePopup() {
    props.ShowHide();
  }

  function Play() {
    if (playerNodesCopy.length == 0) {
      alert("You need to add audio files before you play");
      gEvent("Played w/o Files", "Transport", "Error");
      return;
    } else if (isPlaying) {
      gEvent("Pause Button", "Transport", "Pause");
      return;
    }

    var i = 0;
    for (let pNode of playerNodesCopy) {
      var source = audioContext.createBufferSource(); // Re-Create sound source from the copyNodes because they have not yet
      source.buffer = pNode.buffer;
      source.loop = true;
      source.start(0, 0);
      source.connect(mNode);
      if (mixer.channels[i]) {
        console.log(mixer.channels[i]);
        source.connect(mixer.channels[i].InputGain);
      }
      playerNodes.push(source);
      i++;
    }
    setIsPlaying((isPlaying) => true);
    setNodes((playerNodes) => [...playerNodes]);
  }

  function Stop() {
    for (let pNode of playerNodes) {
      pNode.stop();
    }
    setIsPlaying(false);
    setNodes([]);
    gEvent("Stop Button", "Transport", "Stop");
  }

  async function AddDemo() {
    setNodesCopy([]); //clears the copy nodes (and the labels in the fileplayer)
    setFileNames([]);
    document.getElementById("spinner").removeAttribute("hidden");

    await CreateSource(DemoFile1);
    await CreateSource(DemoFile2);
    await CreateSource(DemoFile3);
    await CreateSource(DemoFile4);

    document.getElementById("spinner").setAttribute("hidden", ""); //need to change this for when process completes
    gEvent("Add Demo Session", "FilePlayer", "Demo Session #1");
  }

  async function CreateSource(URL = "https://s3-us-west-2.amazonaws.com/s.cdpn.io/123941/Yodel_Sound_Effect.mp3") {
    console.log("one more time");
    var source = audioContext.createBufferSource(); // Create sound source
    setNodesCopy((prevCount) => [...prevCount, source]);
    file_name = URL.split("/").pop(); //Add Filenames when they are loaded
    file_name = file_name.split(".")[0];
    setFileNames((prevCount) => [...prevCount, file_name]);

    var file_name;
    await window
      .fetch(URL)
      .then((response) => response.arrayBuffer())
      .then((arrayBuffer) =>
        audioContext.decodeAudioData(
          arrayBuffer,
          (audioBuffer) => {
            source.buffer = audioBuffer; // Add the buffered data
          },
          (error) => console.error(error)
          // (error) => alert(error)
        )
      );
  }

  function AddSlot(pop = false) {
    if (!pop) {
      CreateSource();
    } else {
      if (isPlaying) playerNodes[playerNodes.length - 1].stop();
      playerNodesCopy.pop();
    }

    setNodesCopy((playerNodesCopy) => [...playerNodesCopy]);
  }

  //This function is needed for forwardRef calls
  useImperativeHandle(ref, () => ({
    PlayAudio() {
      Play();
    },

    StopAudio() {
      Stop();
    },
  }));

  useEffect(() => {}, []); //will run if there are changes to these variables

  return (
    <div className="Player" onKeyPress={escapePopup}>
      {isShowing && (
        <div className="Popup">
          <button id="closePopup" onClick={() => props.ShowHide()}>
            X
          </button>
          <h2>Audio File Player</h2>
          <p></p>
          <button id="AddSlot" onClick={() => AddDemo()}>
            Demo Session
          </button>
          <div hidden id="spinner"></div>

          <br />
          <br />

          {/* <button id="AddSlot" onClick={() => AddSlot()}>
            +
          </button>
          <button id="DelSlot" onClick={() => AddSlot(true)}>
            -
          </button> */}

          {fileNames.map((file) => (
            <div className="file-label">{file}</div>
          ))}
          <div className="my-peak-meter">
            <small>~ This meter shows what is feeding the mixer ~</small>
            <PeakMeter2 audioCtx={audioContext} sourceNodes={[mNode]} channels={2} showing={isShowing} />
          </div>
        </div>
      )}
    </div>
  );
});

PlayerPopup.displayName = "PlayerPopup";
export default PlayerPopup;
