import "./transportBar.css";
import { useState } from "react";
import React, { useRef } from "react";
import PlayerPopup from "../PlayerPopup/playerPopup.js";
import { useAudio } from "../../lib/ContextProvider";
import { gEvent } from "../../lib/gTagAnalytics";

const Transportbar = () => {
  const { audioContext } = useAudio();

  const [isShowing, setIsShowing] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const childRef = useRef();

  function clicked() {
    var thing = document.getElementsByClassName("shell")[0];
    var style = getComputedStyle(thing);
    thing.style.zoom = parseFloat(style.zoom) + 0.1;
    gEvent("Zoom In Button", "Transport", "Zoom In");
  }

  function clicked2() {
    var thing = document.getElementsByClassName("shell")[0];
    var style = getComputedStyle(thing);
    thing.style.zoom = parseFloat(style.zoom) - 0.1;
    gEvent("Zoom Out Button", "Transport", "Zoom Out");
  }

  function PlayAudio() {
    audioContext.resume();

    // if (!isPlaying)
    //can't play twice!
    childRef.current.PlayAudio();
    // setIsPlaying(true);
  }

  function StopAudio() {
    childRef.current.StopAudio();
    // setIsPlaying(false);
  }

  function ShowHidePlayer() {
    if (isShowing) {
      setIsShowing(false);
      gEvent("FilePlayer Button", "Transport", "Hide");
    } else {
      setIsShowing(true);
      gEvent("FilePlayer Button", "Transport", "Show");
    }
  }

  document.addEventListener(
    "keydown",
    (event) => {
      const keyName = event.key;

      if (keyName == "Escape") {
        setIsShowing(false);
        gEvent("FilePlayer Button", "Transport", "Hide");
        gEvent("Escape Key", "Key Commands", "Hide FilePlayer");
      }
      if (keyName == "f") {
        gEvent("F Key", "Key Commands", "Show/Hide FilePlayer");
        ShowHidePlayer();
      }
    },
    { once: true }
  ); //Is it really calling once?

  return (
    <div className="footer">
      <PlayerPopup ref={childRef} display={isShowing} ShowHide={ShowHidePlayer} />
      <div className="button-container">
        <button onClick={clicked2}>-</button>
        <button onClick={clicked}>+</button>
        <button onClick={StopAudio}>Stop</button>
        <button onClick={(audioContext.resume(), PlayAudio)}>Play</button>
        <button className="file-button" onClick={ShowHidePlayer}>
          Player
        </button>

        {/* <div className="window-buttons"> */}

        {/* </div> */}
      </div>
    </div>
  );
};

export default Transportbar;
