import knobs from "../../lib/knobOptions";
import WebKnob from "../WebKnob/WebKnob";
import "./Channelstrip.css";

const Channel = ({ channel, mst, aux, audioContext }) => {
  //RIG THE CHANNEL CONNECTIONS ~ eventually the parameters could alter this setup!
  channel.InputGain.connect(channel.HF);
  channel.HF.connect(channel.MF);
  channel.MF.connect(channel.LF);
  channel.LF.connect(channel.FX);
  channel.FX.connect(aux);
  channel.LF.connect(channel.BAL);
  channel.BAL.connect(channel.VOL);
  channel.VOL.connect(mst);

  channel.InputGain.gain.value = 0;
  channel.FX.gain.value = 0;
  channel.VOL.gain.value = 0;

  return (
    <div className="mixer-shell2">
      <div className="channel-shell">
        <WebKnob src={knobs.multi.black} type="gain" node={channel.InputGain} value={0} />
        Gain
        <WebKnob type="highshelf" node={channel.HF} value={50} />
        HI
        <WebKnob type="peaking" node={channel.MF} value={50} />
        MID
        <WebKnob type="lowshelf" node={channel.LF} value={50} />
        LOW
        <div
          style={{
            backgroundColor: "black",
            paddingTop: "10px",
            paddingBottom: "10px",
            color: "white",
            borderColor: "gray",
            borderStyle: "solid",
            border: "1px",
          }}
        >
          <WebKnob src={knobs.multi.red} type="gain" node={channel.FX} value={0} />
          FX
        </div>
        PAN
        <WebKnob src={knobs.multi.black} type="bal" node={channel.BAL} value={50} />
        <WebKnob src={knobs.multi.white} type="gain" node={channel.VOL} value={0} />
        LEVEL
      </div>
    </div>
  );
};

export default Channel;
