import React, { createContext, useContext, useState } from "react";
import { generateBiquadFilter, generateReverb, generatePanner } from "./CreateAudioNodes.js";

const WebAudioContext = window.AudioContext || window.webkitAudioContext;
const audioContext = new WebAudioContext();

const AudioContext = createContext({
  audioContext: {},
});

export const useAudio = () => useContext(AudioContext);

class XenaOptions {
  constructor() {
    this.InputGain = audioContext.createGain();
    this.HF = generateBiquadFilter(audioContext, 12200, 1, "highshelf");
    this.MF = generateBiquadFilter(audioContext, 1200, 1, "peaking");
    this.LF = generateBiquadFilter(audioContext, 500, 1, "lowshelf");
    this.FX = audioContext.createGain();
    this.BAL = generatePanner(audioContext);
    this.VOL = audioContext.createGain();
  }
}

export const AudioProvider = ({ children }) => {
  const [mixer, setMixer] = useState({
    audioContext,
    mst: [
      {
        mst_gain: audioContext.createGain(),
        CTRL: audioContext.createGain(),
        AuxReturn: audioContext.createGain(),
        Reverb: generateReverb(audioContext),
      },
    ],
    channels: [new XenaOptions(), new XenaOptions(), new XenaOptions(), new XenaOptions()],
  });

  // Set provider values
  const value = { audioContext, mixer, setMixer };

  return <AudioContext.Provider value={value}>{children}</AudioContext.Provider>;
};
