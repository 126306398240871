import knobs from "../../lib/knobOptions";
import "./WebKnob.css";
import React, { useEffect } from "react";
import { gEvent } from "../../lib/gTagAnalytics";

const WebKnob = ({
  src = knobs.multi.blue,
  value,
  size = 75,
  min = 0,
  max = 100,
  sprites = 62,
  step = 1,
  style,
  node,
  type = "gain",
  onChange,
}) => {
  const knobRef = React.createRef();

  useEffect(() => {
    const knob = knobRef.current;
    var val;
    knob.addEventListener("change", (e) => {
      if (node != null) {
        val = e.target.value;
        if (type === "gain" || type === "fx") {
          node.gain.value = e.target.value / 100;
        } else if (type === "bal") {
          var val = e.target.value / 50 - 1;
          console.log(val);
          node.setPosition(val, 0, 1 - Math.abs(val));
        } else if (type === "lowshelf" || type === "peaking" || type === "highshelf") {
          node.gain.value = (e.target.value / 50 - 1) * 25;
        }
        // console.log(node.gain.value);
        gEvent(type, "WebKnob", "Value = " + e.target.value);
      }
    });
  }, [knobRef, node]);

  return (
    <div>
      <webaudio-knob
        ref={knobRef}
        id="knob1"
        src={src}
        data-controls="Pan1"
        sprites={sprites}
        min={min}
        max={max}
        value={value}
        width={size}
        height={size}
        step={step}
        style={{ textAlign: "left" }}
      />
    </div>
  );
};

export default WebKnob;
