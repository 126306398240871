import blackMultiKnob from "../assets/spritesheets/knobs/black-multi-knob.png";
import blueMultiKnob from "../assets/spritesheets/knobs/blue-multi-knob.png";
import redMultiKnob from "../assets/spritesheets/knobs/red-multi-knob.png";
import whiteMultiKnob from "../assets/spritesheets/knobs/white-multi-knob.png";

const knobOptions = {
  multi: {
    black: blackMultiKnob,
    blue: blueMultiKnob,
    red: redMultiKnob,
    white: whiteMultiKnob,
  },
};

export default knobOptions;
