import "./App.css";
import Navbar from "./components/NavBar/navBar.js";
import Transportbar from "./components/TransportBar/transportBar";
import Body from "./components/MainBody/body";
import { AudioProvider } from "./lib/ContextProvider";
import { useEffect } from "react";

window.addEventListener("keydown", function (e) {
  if (e.keyCode == 32 && e.target == document.body) {
    e.preventDefault();
  }
});

function App() {
  useEffect(() => {
    window.gtag("event", "App Loaded", {
      app_name: "SoundcheckPro WebApp",
      screen_name: "Main",
    });
  }, []);

  return (
    <div className="App">
      <AudioProvider>
        <div id="header">
          <Navbar />{" "}
        </div>
        <div id="content">
          <Body />{" "}
        </div>
        <div id="footer">
          <Transportbar />{" "}
        </div>
      </AudioProvider>
    </div>
  );
}

export default App;
