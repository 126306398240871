

export const generatePanner =(audioContext)=>{
  let node = audioContext.createPanner();
  node.panningModel = 'equalpower';
  node.setPosition(0, 0, 1);
  return node;
};


export const generateBiquadFilter = (audioContext,frequency, gain, type) => {
    let node = audioContext.createBiquadFilter();
    if (type) node.type = type;
    if (frequency) node.frequency.value = frequency;
    if (gain) node.gain.value = gain;
    return node;
  };

  function impulseResponse(audioContext, duration, decay, reverse ) {
    var sampleRate = audioContext.sampleRate;
    var length = sampleRate * duration;
    var impulse = audioContext.createBuffer(2, length, sampleRate);
    var impulseL = impulse.getChannelData(0);
    var impulseR = impulse.getChannelData(1);

    if (!decay)
        decay = 2.0;
    for (var i = 0; i < length; i++){
      var n = reverse ? length - i : i;
      impulseL[i] = (Math.random() * 2 - 1) * Math.pow(1 - n / length, decay);
      impulseR[i] = (Math.random() * 2 - 1) * Math.pow(1 - n / length, decay);
    }
    return impulse;
  }
  export const generateReverb = (audioContext) =>{
    var reverb;
  reverb = audioContext.createConvolver();
  reverb.buffer = impulseResponse(audioContext, 2,2,false);

  return reverb;
  }