import knobs from "../../lib/knobOptions";
import WebKnob from "../WebKnob/WebKnob";
import "./body.css";
import Channel from "../Channels/Channelstrip";
import PeakMeter2 from "../PeakMeter/PeakMeter2";
import { useAudio } from "../../lib/ContextProvider";
import Logo from "../../assets/Logos/soundcheckpro.png";

const Body = () => {
  const { audioContext, mixer } = useAudio();

  function clicked() {
    var thing = document.getElementsByClassName("shell")[0];
    var style = getComputedStyle(thing);
    thing.style.zoom = parseFloat(style.zoom) + 0.1;
  }

  function clicked2() {
    var thing = document.getElementsByClassName("shell")[0];
    var style = getComputedStyle(thing);
    thing.style.zoom = parseFloat(style.zoom) - 0.1;
  }

  var testCh;
  mixer.mst[0].mst_gain.gain.value = 0;
  mixer.mst[0].CTRL.gain.value = 0;
  mixer.mst[0].AuxReturn.gain.value = 0;

  mixer.mst[0].AuxReturn.connect(mixer.mst[0].Reverb);
  mixer.mst[0].Reverb.connect(mixer.mst[0].mst_gain);
  mixer.mst[0].mst_gain.connect(audioContext.destination);

  // mixer.mst[0].mst_gain.connect(mixer.mst[0].CTRL);
  // mixer.mst[0].CTRL.connect(audioContext.destination);

  const channels = mixer.channels.map(function (fruit, index) {
    return (
      <Channel
        channel={mixer.channels[index]}
        audioContext={audioContext}
        mst={mixer.mst[0].mst_gain}
        aux={mixer.mst[0].AuxReturn}
      />
    );
  });

  return (
    <div className="main">
      <div className="Oshell">
        <div className="IOshell">
          <div className="shell">
            <div className="iShell">
              {channels}
              <div className="Master-Section-Shell">
                <h1>XENA</h1>
                <span>1.0.2</span>
                <br />
                <img src={Logo} />
                <div className="Grey-Frame">
                  <div>
                    <WebKnob style={{ left: "20px" }} type="gain" node={mixer.mst[0].AuxReturn} value={0} />
                    AUX RTN
                  </div>

                  <PeakMeter2
                    audioCtx={audioContext}
                    channels={2}
                    sourceNodes={[mixer.mst[0].mst_gain]}
                    showing={true}
                  />
                  <div style={{ height: "20px" }} />

                  <div className="Master-Gain-Knob">
                    <div>
                      <WebKnob src={knobs.multi.white} type="gain" node={mixer.mst[0].CTRL} value={0} />
                      CTRL
                    </div>
                    <div>
                      <WebKnob type="gain" node={mixer.mst[0].mst_gain} value={0} />
                      MAIN
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="window-buttons">
                  
        <button onClick={clicked2}>-</button>
        <button onClick={clicked}>+</button>
      </div> */}
    </div>
  );
};

export default Body;
